import ModalVideo from 'modal-video';
export default class ModalVideoWithParameters {
    static init($root = $('body')) {
        const $videoButton = $root.find('.js__video-modal-button');
        if (!$videoButton.length) {
            return;
        }
        $videoButton.each((_, element) => {
            const $element = $(element);
            const ratio = $element.data('ratio') || '16:9';
            element.setAttribute('data-channel', 'video');
            element.setAttribute('data-video-url', element.href);
            new ModalVideo($element, { ratio });
        });
    }
}
