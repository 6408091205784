export var Breakpoint;
(function (Breakpoint) {
    Breakpoint[Breakpoint["xs"] = 0] = "xs";
    Breakpoint[Breakpoint["sm"] = 576] = "sm";
    Breakpoint[Breakpoint["md"] = 768] = "md";
    Breakpoint[Breakpoint["lg"] = 992] = "lg";
    Breakpoint[Breakpoint["xl"] = 1200] = "xl";
})(Breakpoint || (Breakpoint = {}));
export var DeviceScreenType;
(function (DeviceScreenType) {
    DeviceScreenType["Mobile"] = "mobile";
    DeviceScreenType["Desktop"] = "desktop";
})(DeviceScreenType || (DeviceScreenType = {}));
