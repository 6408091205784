import $ from 'jquery';
import Timer from '../../components/timer';
import MainSlider from './components/main-slider';
import HeetsSlider from './components/heets-slider';
import RevealWhenVisible from '../../components/reveal-when-visible';
import ModalVideoWithParameters from '../../components/modal-video-with-parameters';
$(() => {
    MainSlider.init();
    HeetsSlider.init();
    Timer.init();
    RevealWhenVisible.init();
    ModalVideoWithParameters.init();
});
