import $ from 'jquery';
import SwiperCore, { Navigation } from 'swiper/core';
import Swiper from 'swiper';
import LazyImages from '../../../components/lazy-images';
import { Breakpoint } from '../../../types/breakpoint';
export default class HeetsSlider {
    static $slider = $('.js__heets-slider');
    static slider;
    static options = {
        slidesPerView: 'auto',
        loop: true,
        centeredSlides: true,
        spaceBetween: 22,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        breakpoints: {
            [Breakpoint.md]: {
                spaceBetween: 74,
            },
        },
    };
    static init() {
        if (!this.$slider.length) {
            return;
        }
        SwiperCore.use([Navigation]);
        this.slider = new Swiper(this.$slider.get(0), {
            ...this.options,
        });
        LazyImages.update();
    }
}
